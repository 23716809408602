import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step beta ca acme eab list`}</strong>{` -- list all ACME External Account Binding Keys`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca acme eab list <provisioner> [<eab-key-reference>]
[--limit=<number>]
[--admin-cert=<file>] [--admin-key=<file>] [--admin-subject=<subject>]
[--admin-provisioner=<name>] [--admin-password-file=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca acme eab list`}</strong>{` lists all ACME External Account Binding (EAB) Keys.`}</p>
    <p>{`Output will go to stdout by default. If many EAB keys are stored in the ACME provisioner, output will be sent to $PAGER (when set). `}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`provisioner`}</inlineCode>{`
Name of the provisioner to list ACME EAB keys for`}</p>
    <p><inlineCode parentName="p">{`eab-key-reference`}</inlineCode>{`
(Optional) reference (from external system) for the key to be listed`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--limit`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
The number of entities to return per (paging) API request.`}</p>
    <p><strong parentName="p">{`--no-pager`}</strong>{`
Disables usage of $PAGER for paging purposes`}</p>
    <p><strong parentName="p">{`--admin-cert`}</strong>{`=`}<inlineCode parentName="p">{`chain`}</inlineCode>{`
Admin certificate (`}<inlineCode parentName="p">{`chain`}</inlineCode>{`) in PEM format to store in the 'x5c' header of a JWT.`}</p>
    <p><strong parentName="p">{`--admin-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Private key `}<inlineCode parentName="p">{`file`}</inlineCode>{`, used to sign a JWT, corresponding to the admin certificate that will
be stored in the 'x5c' header.`}</p>
    <p><strong parentName="p">{`--admin-subject`}</strong>{`=`}<inlineCode parentName="p">{`subject`}</inlineCode>{`, `}<strong parentName="p">{`--admin-name`}</strong>{`=`}<inlineCode parentName="p">{`subject`}</inlineCode>{`
The admin `}<inlineCode parentName="p">{`subject`}</inlineCode>{` to use for generating admin credentials.`}</p>
    <p><strong parentName="p">{`--admin-provisioner`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<strong parentName="p">{`--admin-issuer`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The provisioner `}<inlineCode parentName="p">{`name`}</inlineCode>{` to use for generating admin credentials.`}</p>
    <p><strong parentName="p">{`--admin-password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`, `}<strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to decrypt the one-time token
generating key.`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`List all ACME External Account Binding Keys:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca acme eab list my_acme_provisioner
`}</code></pre>
    <p>{`Show ACME External Account Binding Key with specific reference:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca acme eab list my_acme_provisioner my_reference
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      